import { inject, DestroyRef, signal, effect, untracked, computed } from '@angular/core';
import { assertInjector } from 'ngxtension/assert-injector';
import { Subject, isObservable, of, mergeAll, concatAll, exhaustAll, switchAll } from 'rxjs';

function derivedAsync(computation, options = {}) {
    return assertInjector(derivedAsync, options?.injector, () => {
        const destroyRef = inject(DestroyRef);
        // source$ is a Subject that will emit the new source value
        const sourceEvent$ = new Subject();
        // enhance the sourceEvent$ with the behavior
        const source$ = createFlattenObservable(sourceEvent$, options?.behavior ?? 'switch');
        const sourceResult = source$.subscribe({
            next: (value) => sourceValue.set({ kind: 1 /* StateKind.Value */, value }),
            // NOTE: Error should be handled by the user (using catchError or .catch())
            error: (error) => sourceValue.set({ kind: 2 /* StateKind.Error */, error }),
        });
        // we need to unsubscribe the sourceResult when the context gets destroyed
        destroyRef.onDestroy(() => sourceResult.unsubscribe());
        // sourceValue is a signal that will hold the current value and the state of the value
        let sourceValue;
        if (options?.requireSync && options?.initialValue === undefined) {
            const initialCmp = computation(undefined);
            // we don't support promises with requireSync and no initialValue also the typings don't allow this case
            if (isPromise(initialCmp)) {
                throw new Error(REQUIRE_SYNC_PROMISE_MESSAGE);
            }
            sourceValue = signal({ kind: 0 /* StateKind.NoValue */ });
            if (isObservable(initialCmp)) {
                sourceEvent$.next(initialCmp);
            }
            else {
                sourceValue.set({ kind: 1 /* StateKind.Value */, value: initialCmp });
            }
        }
        else {
            sourceValue = signal({
                kind: 1 /* StateKind.Value */,
                value: options?.initialValue,
            });
        }
        if (options?.requireSync && sourceValue().kind === 0 /* StateKind.NoValue */) {
            throw new Error(REQUIRE_SYNC_ERROR_MESSAGE);
        }
        let skipFirstComputation = options?.requireSync === true;
        // effect runs inside injection context, so it will be cleanup up when context gets destroyed
        effect(() => {
            // we need to have an untracked() here because we don't want to register the sourceValue as a dependency
            // otherwise, we would have an infinite loop.
            // this is needed for previousValue feature to work
            const currentValue = untracked(() => {
                const currentSourceValue = sourceValue();
                return currentSourceValue.kind === 1 /* StateKind.Value */
                    ? currentSourceValue.value
                    : undefined;
            });
            const newSource = computation(currentValue);
            // we need to skip the first computation if requireSync is true
            // because we already computed the value in the previous step
            if (skipFirstComputation) {
                skipFirstComputation = false;
                return;
            }
            // we untrack the source$.next() so that we don't register other signals as dependencies
            untracked(() => {
                sourceEvent$.next(isObservable(newSource) || isPromise(newSource)
                    ? newSource
                    : of(newSource));
            });
        });
        // we return a computed value that will return the current value
        // in order to support the same API as computed()
        return computed(() => {
            const state = sourceValue();
            switch (state.kind) {
                case 1 /* StateKind.Value */:
                    return state.value;
                case 2 /* StateKind.Error */:
                    throw state.error;
                case 0 /* StateKind.NoValue */:
                    // we already throw an error if requireSync is true and there is no initialValue,
                    // so we don't need to throw an error here
                    return;
                default:
                    // we should never reach this case
                    throw new Error('Unknown state');
            }
        }, { equal: options?.equal });
    });
}
const REQUIRE_SYNC_PROMISE_MESSAGE = `Promises cannot be used with requireSync. Pass an initialValue or set requireSync to false.`;
const REQUIRE_SYNC_ERROR_MESSAGE = `The observable passed to derivedAsync() did not emit synchronously. Pass an initialValue or set requireSync to false.`;
function createFlattenObservable(source, behavior) {
    const KEY_OPERATOR_MAP = {
        merge: mergeAll,
        concat: concatAll,
        exhaust: exhaustAll,
        switch: switchAll,
    };
    return source.pipe(KEY_OPERATOR_MAP[behavior]());
}
function isPromise(value) {
    return value && typeof value.then === 'function';
}

/**
 * Generated bundle index. Do not edit.
 */

export { derivedAsync };

