import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { catchError, map, of, Subject, switchMap, tap } from 'rxjs';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import type { LoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, type OffsetPagingWrapper } from '@clover/core/helpers/paging';

import { TaskActivityService, type TaskActivityEntry } from './task-activity.service';
import type { Task } from '../../tasks.model';
import { TaskDrawerActivityEntryComponent } from './task-drawer-activity-entry/task-drawer-activity-entry.component';

@Component({
  selector: 'cc-task-drawer-activity',
  standalone: true,
  templateUrl: './task-drawer-activity.component.html',
  styleUrls: ['./task-drawer-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TaskDrawerActivityEntryComponent,
    NgScrollbarModule,
    InfiniteScrollDirective,
    CdkScrollable,
    AutoAnimateDirective,
  ],
})
export class TaskDrawerActivityComponent {
  task = input.required<Task>();

  private readonly taskActivityService = inject(TaskActivityService);
  private readonly destroyRef = inject(DestroyRef);

  protected readonly activity = signal<OffsetPagingWrapper<TaskActivityEntry>>(EMPTY_PAGING);
  protected readonly loadingStatus = signal<LoadingStatus>('void');
  protected readonly loadNext$ = new Subject<void>();

  constructor() {
    this.initActivity();
  }

  private initActivity(): void {
    toObservable(this.task)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // Loading initial activity (based on task)
        tap(() => this.loadingStatus.set('loading')),
        switchMap((task) => this.taskActivityService.searchTaskActivity(task.id, { limit: 30 })),
        map((activity) => {
          this.activity.set(activity);
          this.loadingStatus.set('loaded');
        }),
        // Loading next activity
        switchMap(() => this.loadNext$),
        tap(() => this.loadingStatus.set('loading-next')),
        switchMap(() =>
          this.taskActivityService.searchTaskActivity(this.task().id, {
            limit: 30,
            offset: this.activity().count,
          }),
        ),
        map((activity) => {
          this.activity.update((prev) => ({
            ...activity,
            data: [...prev.data, ...activity.data],
            count: prev.count + activity.data.length,
          }));
          this.loadingStatus.set('loaded');
        }),
        catchError((error) => {
          this.loadingStatus.set('error');
          console.error(error);

          return of(EMPTY_PAGING);
        }),
      )
      .subscribe();
  }
}
