<div class="task-drawer-results">
  <app-button
    (click)="openTaskResults()"
    [disabled]="tokenLoadingStatus() !== 'loaded'"
    [size]="ButtonSize.Small"
    [type]="ButtonType.Secondary"
  >
    <!-- Spacer -->
    <div
      style="width: 18px"
      slot="start-icon"
    ></div>

    View task results

    <i
      class="icon-external"
      slot="end-icon"
    ></i>
  </app-button>
</div>
