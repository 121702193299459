<div class="task-drawer">
  <div class="task-drawer__header">
    <div class="task-drawer__header-actions">
      <app-button
        (click)="drawerClose.emit()"
        [ccTooltip]="'common.buttons.close' | translate"
        [size]="ButtonSize.Small"
        [tooltipAlignment]="TooltipAlignment.Start"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-escape"
          slot="ghost-icon"
        ></i>
      </app-button>

      <div class="task-drawer__header-actions-title">
        {{ 'conversations-v4.tasks.taskInfo' | translate }}
      </div>

      @if (canCloseTask()) {
        <app-button
          (click)="closeTask()"
          [size]="ButtonSize.Small"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.tasks.closeTask' | translate"
        >
          <i
            class="icon-task-close"
            slot="ghost-icon"
          ></i>
        </app-button>
      }

      @if (canSendReminder()) {
        <app-button
          (click)="sendReminder()"
          [size]="ButtonSize.Small"
          [type]="ButtonType.Ghost"
          [ccTooltip]="'conversations-v4.tasks.sendReminder' | translate"
        >
          <i
            class="icon-notification-up"
            slot="ghost-icon"
          ></i>
        </app-button>
      }

      <app-button
        (click)="copyLink()"
        [ccTooltip]="'conversations-v4.tasks.copyLink' | translate"
        [size]="ButtonSize.Small"
        [tooltipAlignment]="TooltipAlignment.End"
        [type]="ButtonType.Ghost"
      >
        <i
          class="icon-link"
          slot="ghost-icon"
        ></i>
      </app-button>
    </div>

    <div class="task-drawer__header-details">
      <cc-task-status-chip [status]="task().status"></cc-task-status-chip>

      <div class="task-drawer__header-details-title">
        {{ task().name }}
      </div>

      <div class="task-drawer__header-details-metadata">
        <span class="task-drawer__header-details-metadata-id">#{{ task().id }}</span>
        <div class="task-drawer__header-details-metadata-divider"></div>
        <span class="task-drawer__header-details-metadata-status">
          {{
            'conversations-v4.tasks.assignedByAgo'
              | translate
                : {
                    name: task().assignerCompany.name,
                    ago:
                      task().assignedAt
                      | ccDateFormatDistance
                        : {
                            strict: true,
                            locale: getTaskAssignedAtLocale(),
                          },
                  }
          }}
        </span>
      </div>
    </div>
  </div>

  <div class="task-drawer__tabs">
    <cc-tab-selector
      (selectedTabIdChange)="selectTab($event)"
      [selectedTabId]="activeTab"
      [tabs]="tabs()"
      class="task-drawer__tabs-select"
      [inlinePadding]="4"
    />

    <div
      class="task-drawer__tabs-content"
      [ccAutoAnimate]
    >
      @switch (activeTab) {
        @case ('details') {
          <cc-task-drawer-details [task]="task()" />
        }
        @case ('activity') {
          <cc-task-drawer-activity [task]="task()" />
        }
        @case ('results') {
          @if (canViewResults()) {
            <cc-task-drawer-results [task]="task()" />
          }
        }
      }
    </div>
  </div>

  <div class="task-drawer__custom-actions">
    <ng-content></ng-content>
  </div>
</div>
