<div
  style="height: 100%"
  [ccAutoAnimate]
>
  @if (
    ['loaded', 'loading-next'].includes(loadingStatus()) ||
    (activity().data.length > 0 && loadingStatus() === 'loading')
  ) {
    <ng-scrollbar
      class="task-drawer-activity--scrollbar"
      (scrolled)="loadNext$.next()"
      [infiniteScrollDistance]="4"
      [infiniteScrollThrottle]="0"
      [infiniteScrollUpDistance]="4"
      [scrollWindow]="false"
      cdkScrollable
      infiniteScroll
    >
      <div class="task-drawer-activity">
        <div
          class="task-drawer-activity__entries"
          [ccAutoAnimate]
        >
          @for (entry of activity().data; track entry.id) {
            <cc-task-drawer-activity-entry [entry]="entry" />
          }
        </div>

        <div class="task-drawer-activity__timeline"></div>
      </div>
    </ng-scrollbar>
  } @else {
    <div class="task-drawer-activity-placeholder">
      <i class="icon-tasks"></i>
    </div>
  }
</div>
