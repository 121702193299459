<div class="activity-entry">
  <div class="activity-entry__icon">
    <i [class]="iconClass()"></i>
  </div>

  <div class="activity-entry__content">
    <div class="activity-entry__content-title">
      @switch (entry().type) {
        @case (EntryType.TaskStarted) {
          Task started
        }
        @case (EntryType.Assigned) {
          <ng-container [ngTemplateOutlet]="assignTemplate" />
        }
        @case (EntryType.Reassigned) {
          <ng-container [ngTemplateOutlet]="assignTemplate" />
        }
        @case (EntryType.TaskAccepted) {
          Task accepted
        }
        @case (EntryType.StepStarted) {
          <ng-container [ngTemplateOutlet]="stepTemplate" />
        }
        @case (EntryType.StepSubmitted) {
          <ng-container [ngTemplateOutlet]="stepTemplate" />
        }
        @case (EntryType.StepRestarted) {
          <ng-container [ngTemplateOutlet]="stepTemplate" />
        }
        @case (EntryType.TaskClosed) {
          Task closed
        }
        @case (EntryType.TaskCompleted) {
          Task completed
        }
        @case (EntryType.ProductTaskCopied) {
          Task response copied from product "{{ entry().metadata.copiedFromProductName }}"
        }
      }
    </div>

    <div class="activity-entry__content-metadata">
      @if (entry().metadata.performer; as performer) {
        <div class="activity-entry__content-metadata-item">
          by
          <cc-user-avatar
            class="cc-avatar"
            [id]="performer.id"
            [src]="performer.avatarUrl"
            [name]="[performer.firstName, performer.lastName]"
            [size]="16"
          />
          {{ performer.firstName }} {{ performer.lastName }}
        </div>
      }

      @if (entry().createdAt; as createdAt) {
        <div class="activity-entry__content-metadata-item">{{ createdAt | ccDateFormatDistance }} ago</div>
      }
    </div>
  </div>
</div>

<ng-template #stepTemplate>
  @if ([EntryType.StepStarted, EntryType.StepSubmitted, EntryType.StepRestarted].includes(entry().type)) {
    @if (entry().metadata.stepName; as stepName) {
      Step "{{ stepName }}"
    } @else {
      Step
    }

    @switch (entry().type) {
      @case (EntryType.StepStarted) {
        started
      }
      @case (EntryType.StepSubmitted) {
        submitted
      }
      @case (EntryType.StepRestarted) {
        restarted
      }
    }
    <span class="cc-tint">({{ entry().metadata.stepIndex }} of {{ entry().metadata.stepsCount }})</span>
  }
</ng-template>

<ng-template #assignTemplate>
  @if ([EntryType.Assigned, EntryType.Reassigned].includes(entry().type)) {
    @switch (entry().type) {
      @case (EntryType.Assigned) {
        Task assigned to
      }
      @case (EntryType.Reassigned) {
        Task reassigned to
      }
    }

    @switch (assignedToType()) {
      @case ('user') {
        @for (user of entry().metadata.assigneeUsers; track user.id) {
          <cc-user-avatar
            class="cc-avatar"
            [id]="user.id"
            [src]="user.avatarUrl"
            [name]="[user.firstName, user.lastName]"
            [size]="18"
          />
          {{ user.firstName }} {{ user.lastName }}{{ $last ? '' : ', ' }}
        }
      }

      @case ('department') {
        @for (department of entry().metadata.assigneeRoles; track department.id) {
          {{ department.title }}{{ $last ? '' : ', ' }}
        }
      }
    }
  }
</ng-template>
